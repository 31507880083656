import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { User } from '../models/user.model';

const endpoint = environment.baseUrl + 'api/login';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient, private router: Router) { }

  login(user: User): Observable<any> {
    return this.httpClient.post<any>(endpoint, user);
  }

  logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('username');

    this.router.navigate(['/login']);
  }

  loggedIn(): boolean {
    return !!sessionStorage.getItem('token');
  }

  getRole(): string {
    return sessionStorage.getItem('role');
  }
}
