import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QueueInstance } from '../models/queue-instance.model';

const endpoint = environment.baseUrl + 'api/queue';

@Injectable({
  providedIn: 'root'
})
export class QueueService {

  constructor(private httpClient: HttpClient) { }

  public getEncashers(): Observable<QueueInstance[]> {
    return this.httpClient.get<QueueInstance[]>('../../assets/test.json');
  }

  public getAllQueuedInstances(): Observable<QueueInstance[]> {
    return this.httpClient.get<QueueInstance[]>(`${endpoint}/queued/today`);
  }
  
  public getAppointmentInstances(centerId: string): Observable<QueueInstance[]> {
    return this.httpClient.get<QueueInstance[]>(`${endpoint}/queued/today/${centerId}`);
  }

  public getDisbursementList(): Observable<QueueInstance[]> {
    return this.httpClient.get<QueueInstance[]>(`${endpoint}/disbursement-list`);
  }

  public queueEncasher(queueInstance: QueueInstance): Observable<QueueInstance> {
    return this.httpClient.post<QueueInstance>(endpoint, queueInstance);
  }

  public deQueueEncasher(queueId: number): Observable<any> {
    return this.httpClient.delete(`${endpoint}/today/dequeue/${queueId}`);
  }

  public verifyEncasher(queueInstance: QueueInstance): Observable<QueueInstance> {
    return this.httpClient.patch<QueueInstance>(endpoint + '/verify/' + queueInstance.id, {});
  }

  public updateQueueStatus(queueInstance: QueueInstance): Observable<QueueInstance> {
    return this.httpClient.patch<QueueInstance>(endpoint + '/disburse/' + queueInstance.id, {});
  }
}
