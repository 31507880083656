import { Encasher } from './encasher.model';

export class QueueInstance {
   id?: number;
   batchNumber?: number;
   dateCreated?: string;
   timeCreated?: string;
   queueStatus?: string;
   attendedAt?: string;
   slotTime?: string;
   encasher: Encasher;
}
