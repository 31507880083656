import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { QueueComponent } from './pages/queue/queue.component';
import { DisplayComponent } from './display/display.component';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { DisbursementListComponent } from './pages/disbursement-list/disbursement-list.component';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { VerificationGuard } from './auth/verification.guard';
import { DisbursementGuard } from './auth/disbursement.guard';

const routes: Routes = [
  {
    path: 'verification', redirectTo: '/verification/order-list', pathMatch: 'full'
  },
  {
    path: 'disbursement', redirectTo: '/disbursement/disbursement-list', pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'verification/order-list',
    canActivate: [AuthGuard, VerificationGuard],
    component: OrderListComponent
  },
  {
    path: 'verification/queue',
    canActivate: [AuthGuard, VerificationGuard],
    component: QueueComponent
  },
  {
    path: 'disbursement/disbursement-list',
    canActivate: [AuthGuard, DisbursementGuard],
    component: DisbursementListComponent
  },
  {
    path: 'display',
    loadChildren: () => import('./display/display.module').then(d => d.DisplayModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
