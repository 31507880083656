import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  response: string;
  user: User;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [null],
      password: [null]
    });
  }

  login(loginForm: FormGroup) {	
    this.authService
      .login(loginForm.value)
      .subscribe(
        (res: any) => {
          sessionStorage.setItem('token', res.token);
          sessionStorage.setItem('role', res.role);
          sessionStorage.setItem('username', loginForm.get('username').value);

          const role = (res.role === 'VER') ? '/verification' : '/disbursement';
          this.router.navigate([role]);
        },
        err => {
          console.error(err);
          this.response = 'Invalid username and/or password.';
        });
  }
}
