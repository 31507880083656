import { Component, OnInit } from '@angular/core';
import { Encasher } from 'src/app/models/encasher.model';
import { QueueInstance } from 'src/app/models/queue-instance.model';
import { EncasherService } from 'src/app/services/encasher.service';
import { QueueService } from 'src/app/services/queue.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  orderListEncashers: Encasher[];
  queuedInstances: QueueInstance[];
  orderList: QueueInstance[] = [];
  queuedEncasher = new QueueInstance();

  encasher: Encasher;
  code: number;

  successMsg = '';
  errorMsg = '';

  queued = false;

  constructor(
    private encasherService: EncasherService,
    private queueService: QueueService
  ) { }


  ngOnInit() {
    this.onlyQueued();
    this.getQueueInstances();
  }

  getQueueInstances() {
    this.encasherService
      .getOrderListEncashers()
      .subscribe(
        (suc: Encasher[]) => {
          this.orderListEncashers = suc;
          suc.forEach(
            encasher => {
              const qi = new QueueInstance();
              qi.encasher = encasher;
              this.orderList.push(qi);
            });
        }
      );
  }

  onlyQueued() {
    // Getting all the encasher that are already on the queue.
    this.queueService
      .getAllQueuedInstances()
      .subscribe(
        (suc: QueueInstance[]) => this.queuedInstances = suc,
      );
  }

  queueEncasher(encasher: Encasher, index: number) {
    this.errorMsg = '';
    this.successMsg = '';

    const queue = new QueueInstance();
    queue.encasher = encasher;
    queue.queueStatus = 'queued';

    this.queueService
      .queueEncasher(queue)
      .subscribe(
        (res: QueueInstance) => {
          this.queuedEncasher = res;
          this.successMsg = res.encasher.encasherName + ' from ' + res.encasher.bdc.name + ' successfully queued for verification!';
          this.onlyQueued();
          this.queued = true;
          // print should happen here
        },
        err => {
          this.errorMsg = err.error;
        }
      );
  }

  deQueueEncasher(code: number) {
    this.errorMsg = '';
    this.successMsg = '';

    const queueInstance: QueueInstance = this.queuedInstances
      .find(qi => qi.encasher.encasherCode === code);

    if (queueInstance) {

      this.queueService
        .deQueueEncasher(queueInstance.id)
        .subscribe(
          () => {
            this.successMsg = queueInstance.encasher.encasherName +
              ' from ' + queueInstance.encasher.bdc.name +
              ' removed from the verification queue.';

            this.onlyQueued();
          },
          err => {
            this.errorMsg = err.message;
          }
        );
    }
  }

  getEncasherStatus(encasher: Encasher, queuedInstances: QueueInstance[]): string {
    const quin = queuedInstances.find(q => q.encasher.encasherCode === encasher.encasherCode);
    return (quin) ? quin.queueStatus : null;
  }

  alreadyQueued(encasher: Encasher, queuedInstances: QueueInstance[]): boolean {
    if (queuedInstances) {
      return !!queuedInstances.find(q => q.encasher.encasherCode === encasher.encasherCode);
    }
    return false;
  }

  searchEncasher() {
    if (this.code) {
      this.encasherService
        .getEncasherByCode(this.code)
        .subscribe(
          (encasher: Encasher) => this.encasher = encasher,
          (error: Error) => this.errorMsg = error.message
        );
    }
  }

  donePrinting() {
    this.queued = false;
  }

}
// 9820