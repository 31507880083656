import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { QueueComponent } from './pages/queue/queue.component';
import { QueueFormComponent } from './pages/queue-form/queue-form.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DisplayComponent } from './display/display.component';
import { DisplayModule } from './display/display.module';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { VerificationComponent } from './pages/verification/verification.component';
import { DisbursementListComponent } from './pages/disbursement-list/disbursement-list.component';
import { NgxPrintModule } from 'ngx-print';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './services/auth.service';
import { AppHttpInterceptorService } from './services/http-interceptor.service';
import { AuthInterceptorService } from './services/auth-interceptor.service';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    QueueComponent,
    QueueFormComponent,
    DisplayComponent,
    OrderListComponent,
    VerificationComponent,
    DisbursementListComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DisplayModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptorService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
