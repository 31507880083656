import { Component, OnInit } from '@angular/core';
import { ActiveList } from 'src/app/models/active-list.model';
import { ActiveListService } from 'src/app/services/active-list.service';
import { QueueService } from 'src/app/services/queue.service';
import { QueueInstance } from 'src/app/models/queue-instance.model';
import { Bdc } from 'src/app/models/bdc.model';
import { Encasher } from 'src/app/models/encasher.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  activeList: ActiveList[] = [];
  queuedInstances: QueueInstance[];

  successMsg = '';
  errorMsg = '';

  constructor(
    private activeListService: ActiveListService,
    private queueService: QueueService
  ) { }


  ngOnInit() {
    // Getting all the activeList.
    this.onlyQueued();

    this.activeListService.getActiveList()
      .subscribe(
        (suc: ActiveList[]) => {
          this.activeList = suc;
        }
      );
  }

  onlyQueued() {
    // Getting all the activeList that are already on the queue.
    this.queueService
      .getAllQueuedInstances()
      .subscribe(
        (suc: QueueInstance[]) => this.queuedInstances = suc,
      );
  }

  queueEncasher(activeBdc: ActiveList, index: number) {
    this.errorMsg = '';
    this.successMsg = '';

    const bdc = new Bdc();
    const encasher = new Encasher();
    const queue = new QueueInstance();

    // Initializing encasher with name, code, and bdc.
    bdc.code = activeBdc.bdcCode;
    bdc.name = activeBdc.name;
    encasher.encasherCode = activeBdc.encasherCode;
    encasher.encasherName = activeBdc.fullName;
    encasher.bdc = bdc;

    queue.encasher = encasher;
    this.queueService
      .queueEncasher(queue)
      .subscribe(
        () => {
          this.successMsg = queue.encasher.encasherName + ' from ' + queue.encasher.bdc.name + ' successfully queued for verification!';
          this.onlyQueued();
        },
        err => {
          this.errorMsg = err.error;
        }
      );
  }

  alreadyQueued(encasher: ActiveList, activeList: ActiveList[]): boolean {
    if (activeList) {
      return !!activeList.find(m => m.bdcCode === encasher.bdcCode);
    }
    return false;
  }

}
