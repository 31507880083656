import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DisplayRoutingModule } from './display-routing.module';
import { VerificationComponent } from './verification/verification.component';
import { DisbursementComponent } from './disbursement/disbursement.component';

@NgModule({
  declarations: [VerificationComponent, DisbursementComponent],
  imports: [
    CommonModule,
    DisplayRoutingModule
  ]
})
export class DisplayModule { }
