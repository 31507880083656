import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VerificationComponent } from 'src/app/display/verification/verification.component';
import { DisbursementComponent } from 'src/app/display/disbursement/disbursement.component';

const routes: Routes = [
  {
    path: 'verification/:centerId',
    component: VerificationComponent
  },
  {
    path: 'disbursement/:centerId',
    component: DisbursementComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DisplayRoutingModule { }
