import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Encasher } from '../models/encasher.model';
import { environment } from 'src/environments/environment.prod';


const endpoint = environment.baseUrl + 'api/encashers';

@Injectable({
  providedIn: 'root'
})
export class EncasherService {

  constructor(private httpClient: HttpClient) { }

  getOrderListEncashers(): Observable<Encasher[]> {
    return this.httpClient.get<Encasher[]>(endpoint);
  }

  getEncasherByCode(code: number): Observable<Encasher> {
    return this.httpClient.get<Encasher>(endpoint + '/' + code);
  }
}
