import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QueueInstance } from 'src/app/models/queue-instance.model';
import { QueueService } from 'src/app/services/queue.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  queue: QueueInstance[];
  currentBatch = 0;
  batchSize: string;
  centerId: string;
  rows: number[];
  itemsPerRow: number;

  constructor(private queueService: QueueService,private route: ActivatedRoute) { 
	this.route.paramMap.subscribe(params => {		
		this.centerId = params.get('centerId');		
	});
  }

  ngOnInit() {    	 
	this.loadBatchAppointments();
	setInterval(() => { 
		this.loadBatchAppointments(); 
	}, 300000);
  }
  
  loadBatchAppointments(){
	  this.queueService
      .getAppointmentInstances(this.centerId)
      .subscribe(
        (suc: QueueInstance[]) => {
          this.queue = suc.filter(qi => qi.queueStatus === 'processing');
          this.setCurrentBatch();
		  //if(this.queue != ''){
			this.batchSize = this.queue[0].slotTime;
			
			this.itemsPerRow = 15;
			this.rows = Array.from(Array(Math.ceil(this.queue.length / this.itemsPerRow)).keys());
		  //}
        },
        err => console.error(err)
      );
  }

  setCurrentBatch(): void {
    //this.currentBatch = this.queue.length / this.batchSize;
  }
}
