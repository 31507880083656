import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActiveList } from '../models/active-list.model';


const endpoint = environment.baseUrl + 'api/active-list';

@Injectable({
  providedIn: 'root'
})
export class ActiveListService {

  constructor(private httpClient: HttpClient) { }

  public getActiveList(): Observable<ActiveList[]> {
    return this.httpClient.get<ActiveList[]>(endpoint);
  }
}
