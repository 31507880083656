import { Component, OnInit } from '@angular/core';
import { QueueService } from 'src/app/services/queue.service';
import { QueueInstance } from 'src/app/models/queue-instance.model';

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class QueueComponent implements OnInit {

  queue: QueueInstance[];

  successMsg = '';
  errorMsg = '';

  constructor(private queueService: QueueService) { }

  ngOnInit() {
    this.queueService
      .getAllQueuedInstances()
      .subscribe(
        (suc: QueueInstance[]) => {			
          this.queue = suc.filter(qi => qi.queueStatus === 'queued');		  
        },
        err => console.error(err)
      );
  }

  verify(queueInstance: QueueInstance): boolean {
    this.errorMsg = '';
    this.successMsg = '';

    const qInstance = queueInstance;
    qInstance.queueStatus = 'verified';

    this.queueService
      .verifyEncasher(qInstance)
      .subscribe(
        (success: QueueInstance) => {			
          const queueId = this.queue.findIndex(q => q.id === qInstance.id);
          this.queue.splice(queueId, 1);
          this.successMsg = `${success.encasher.encasherName} successfully verified.`;
          return true;
        },
        error => {
          this.errorMsg = error.message;
          console.error(error);
        }
      );

    return false;
  }

  isServed(queueInstance: QueueInstance): boolean {
    return (queueInstance.queueStatus === 'verified');
  }

}
