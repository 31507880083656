import { Component, OnInit } from '@angular/core';
import { QueueService } from 'src/app/services/queue.service';
import { Encasher } from 'src/app/models/encasher.model';
import { QueueInstance } from 'src/app/models/queue-instance.model';

@Component({
  selector: 'app-disbursement-list',
  templateUrl: './disbursement-list.component.html',
  styleUrls: ['./disbursement-list.component.scss']
})
export class DisbursementListComponent implements OnInit {

  disbursementList: QueueInstance[] = [];

  encasher: Encasher;
  code: number;

  successMsg = '';
  errorMsg = '';

  constructor(
    private queueService: QueueService
  ) { }

  ngOnInit() {
    this.getQueueInstances();
  }

  getQueueInstances() {
    this.queueService
      .getDisbursementList()
      .subscribe(
        (suc: QueueInstance[]) => this.disbursementList = suc
      );
  }

  disburseEncasher(queueInstance: QueueInstance, index: number) {
    this.queueService
      .updateQueueStatus(queueInstance)
      .subscribe(
        () => {
          this.getQueueInstances();
          const disbursed = this.disbursementList.splice(index, 1);
          this.successMsg = `${queueInstance.encasher.encasherName} from ${queueInstance.encasher.bdc.name} is successfully disbursed.`;
		  //this.successMsg = `${queueInstance.bdc.name} is successfully disbursed.`;
        },
        err => this.errorMsg = err.message
      );
  }
}
